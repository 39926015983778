import React from 'react'
import page from '~contexts/page/page'

import { Router } from '@reach/router'
import { useGlobalContext } from '~contexts/globalContext'
import Error404Page from '~components/Pages/Error404Page/Error404Page'
import Route from '~utils/Route'

const CustomPage = ({ slug }) => {
	const { website } = useGlobalContext()

	const current_page = website.getPageFromSlug(slug)

	if (!current_page) {
		return <Error404Page />
	}

	const Page = page({ pageId: current_page.id })

	return <Page />
}

const CustomPageContainer = () => {
	return (
		<Router>
			<Route path="/p/:slug" Component={CustomPage} />
		</Router>
	)
}

export default CustomPageContainer
